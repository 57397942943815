<template>
  <span class="tooltip-container">
    <div>
      <i
        class="fa fa-refresh fa-spin clicable"
        @mouseover="onMouseInOut(true)"
        @mouseleave="onMouseInOut(false)"
        v-show="!displayTooltip"
      />
      <div
        class="box box-default box-solid"
        v-if="displayTooltip"
        @mouseleave="hideDetails()"
        ref="details"
      >
        <div class="custom-box-header with-border">
          <div class="text-bold">{{ entry && entry.name }}</div>
          <div
            class="btn btn-xs"
            data-widget="remove"
            @click="displayTooltip = false"
          >
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="box-body">
          <div style="margin: -5px 0 5px 0;opacity: .7">
            {{ $t("has_pending_commands") }}
          </div>
          <div>
            <table
              v-if="pendingCommands && pendingCommands.length"
              class="table table-bordered table-condensed"
              style="background-color: inherit;opacity: 1;white-space:nowrap; margin-bottom: 0"
            >
              <tbody>
                <tr>
                  <td style="max-width:150px;">{{ $t("value") }}</td>
                  <td>{{ this.$t("datetime") }}</td>
                  <td>{{ this.$tc("sent", 1) }}</td>
                </tr>
                <template v-for="(i, ix) in pendingCommands || []">
                  <tr :key="i.id" :class="{'bg-red': ix == 0}">
                    <td style="max-width:150px;overflow:hidden;">
                      {{ i.writing_value }}
                    </td>
                    <td>{{ $dt.format(i.created_datetime) }}</td>
                    <td :title="i.processed_datetime">
                      <i class="fa fa-check" v-if="i.processed_datetime"></i>
                      <span
                        v-else
                        @mouseenter="ix == 0 ? (dissmissable = true) : 0"
                        @mouseleave="dissmissable = false"
                        @click.stop.prevent="dissmissable && removeFirstItem()"
                      >
                        <i
                          v-if="i.command_state_id"
                          :class="
                            (dissmissable || alerting) && ix == 0
                              ? 'fa fa-trash clicable'
                              : 'fa fa-refresh fa-spin'
                          "
                        ></i>
                        <span v-else>
                          <i class="fa fa-share-alt"> </i>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <i class="fa fa-refresh fa-spin"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "PendingCommandIndicator",
  props: {
    entry: {
      type: Object,
      required: true
    },
    editor: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      tooltipRequested: false,
      displayTooltip: false,
      dissmissable: false,
      alerting: false
    };
  },
  components: {
    // Tooltip
  },
  computed: {
    pendingCommands() {
      let lst = JSON.parse(JSON.stringify(this?.entry?.pending_commands || []));
      // add mapping command to the view
      if (this?.entry?.pending_mapping_write) {
        lst.push(
          JSON.parse(JSON.stringify(this?.entry?.pending_mapping_write))
        );
      }
      lst.sort((a, b) => {
        if (a.created_datetime > b.created_datetime) return -1;
        if (b.created_datetime > a.created_datetime) return 1;
        return 0;
      });
      return lst;
    },
    firstPendingCommand() {
      return this.pendingCommands.find((i) => i.command_state_id == 1);
    }
  },
  methods: {
    remove() {
      let command = this.firstPendingCommand;
      let payload = {
        id: command.id,
        cancel_command: true
      };
      this.$store
        .dispatch("command/save", [payload])
        .then((result) => {
          this.alerting = false;
          if (result && result.length) {
            if (typeof result[0] == "string") {
              this.$swal({
                title: this.$t("item_could_not_be_deleted"),
                text: result[0],
                icon: "error"
              });
            } else {
              // begin - ToDo: move it to the command module
              let lst = this.$store.getters["dashboard/dataList"] || [];
              let data = lst.find((i) => i.id == this.entry.id);
              if (data) {
                let entry = {
                  data_id: data.id,
                  pending_commands: (data.pending_commands || []).filter(
                    (i) => i.id != command.id
                  )
                };
                this.$store.dispatch("dashboard/setDataValue", entry);
              }
              this.$emit("commandRemoved", command.id);
              // end
              let msg = this.$t("you_have_deleted_n_items", {count: 1});
              this.$toasted.show(msg, {
                position: "bottom-right",
                duration: 5000,
                iconPack: "fontawesome",
                icon: "fa-check",
                type: "success",
                action: {
                  icon: "fa-close",
                  onClick: (e, me) => {
                    me.goAway(0);
                  }
                }
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.alerting = false;
        });
    },
    removeFirstItem() {
      let command = null;
      if (this.$can("manage", "DadoEscrita")) {
        command = this.firstPendingCommand;
      }
      if (command) {
        let self = this;
        let message = this.$t("removal_pending_command");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = "";
        field_name += `${this.$t("value")}: ${command.writing_value}`;
        if (field_name.length > 40) {
          field_name = field_name.substring(0, 36) + "...";
        }
        field_name += ` <br> ${this.$t("created_at")}: ${this.$dt.format(
          command.created_datetime
        )}`;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p><div class="text-warning"><i class="${cls}"></i> ${text}</div><br/></p>`;
        let html = "";
        html += `${message}<br/><b>${field_name}</b>${warning}`;
        let wrapper = document.createElement("div");
        wrapper.innerHTML = html;
        this.alerting = true;
        self
          .$swal({
            title: self.$t("are_you_sure"),
            content: wrapper,
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then((confirm) => {
            if (confirm) {
              self.remove();
            } else {
              this.alerting = false;
            }
          });
        return;
      } else if (this.editor) {
        this.alerting = false;
        this.$emit("click");
      }
    },
    onMouseInOut(option) {
      // this.delayedOver(option);
      this.tooltipRequested = option;
      if (option && !this._timer) {
        // this.displayTooltip=true;
        this._timer = setTimeout(() => {
          clearTimeout(this._timer);
          this._timer = null;
          this.displayTooltip = this.tooltipRequested;
          console.log(this.displayTooltip);
        }, 500);
      }
    },
    hideDetails() {
      const self = this;
      setTimeout(() => {
        $(this.$refs.details).fadeOut(400, () => {
          setTimeout(() => {
            self.displayTooltip = false;
          }, 100);
        });
      }, 300);
    }
  }
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  z-index: 999;
}

.tooltip-container > div {
  position: absolute;
  top: 0;
  left: 0;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.box {
  border-radius: 5px;
  text-align: center;
  position: relative;
  box-shadow: 1px 2px 3px #aaa;
  min-width: 200px;
}
.box-body {
  padding: 10px 5px;
}

.custom-box-header {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid gray;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: #ddd;
}
</style>
